import { Button } from '@gnosis.pm/safe-react-components'
import styled from 'styled-components'

const CustomButtom = styled(Button)`
  && {
    margin-right: 10px;
  }
`

function SubmitButton({
  children,
  disabled,
}: {
  children: string
  disabled?: boolean
}): JSX.Element {
  return (
    <CustomButtom size="lg" color="primary" variant="contained" type="submit" disabled=     {disabled}>
      {children}
    </CustomButtom>
  )
}

export default SubmitButton
