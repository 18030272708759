import { createGlobalStyle } from 'styled-components'
import avertaFont from '@gnosis.pm/safe-react-components/dist/fonts/averta-normal.woff2'
import avertaBoldFont from '@gnosis.pm/safe-react-components/dist/fonts/averta-bold.woff2'

const GlobalStyle = createGlobalStyle`
    html {
        height: 100%
    }

    body {
       height: 100%;
       margin: 0px;
       padding: 0px;
       background: white;
    }

    #root {
        height: 100%;
        padding-right: 0.5rem;
    }

    .MuiFormControl-root,
    .MuiInputBase-root {
        width: 100% !important;
    }

    @font-face {
        font-family: 'Averta';
        src: local('Averta'), local('Averta Bold'),
        url(${avertaFont}) format('woff2'),
        url(${avertaBoldFont}) format('woff');
    }

    .deposit-modal {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        align-items: center;
        flex-direction: column;
      
        
        background: #fff;
        overflow: auto;
        border-radius: 10px;
        outline: none;
        padding: 30px;
        box-shadow: 0 5px 10px rgba(0,0,0,0.30);
    }

    .modal-overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.3);
    }

    .ReactModal__Overlay {
        opacity: 0;
        transition: opacity 100ms ease-in-out;
    }
      
    .ReactModal__Overlay--after-open {
        opacity: 1;
    }
      
    .ReactModal__Overlay--before-close {
        opacity: 0;
    }
`

export default GlobalStyle
