import BigNumber from 'bignumber.js'

export const formatTokenValue = (value: number | string, decimals: number): string => {
  return new BigNumber(value).times(`1e-${decimals}`).toFixed()
}

export const formatCurrencyValue = (value: string, currency: string): string => {
  return new Intl.NumberFormat('en-US', { style: 'currency', currency }).format(parseFloat(value))
}

export const formatAmount = (value: string): string => {
  if (value.length > 18) {
    return `${value.slice(0, value.length - 18).split('').reverse().join('').replace(/(.{3})/g, '$1 ').trim().split('').reverse().join('').replace(/\s/g, ',')}.${value.slice(-18)}`
  } else {
    return `0.${value}`
  }
}

export const numberWithSpaces = (x: string): string =>  {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}


// 9 00 0000 0000 0000 0000