import { Button, Loader } from '@gnosis.pm/safe-react-components'
import Flex from './Flex'
import styled from 'styled-components'

const CustomButtom = styled(Button)`
  && {
    width: calc(50%-10px);
  }
`

function CancelButton({ children }: { children: string }): JSX.Element {
  return (
    <>
        <CustomButtom size="lg" color="secondary" type="reset">
          {children}
        </CustomButtom>
    </>
  )
}

export default CancelButton