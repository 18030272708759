import React, { useState, useEffect, useCallback } from 'react'
import { Title, Text } from '@gnosis.pm/safe-react-components'
import { useSafeAppsSDK } from '@gnosis.pm/safe-apps-react-sdk'
import web3Utils from 'web3-utils'
import { BigNumber } from 'bignumber.js'
import styled from 'styled-components'
import Modal from 'react-modal';
import { Contract as EthContract } from 'web3-eth-contract';
import { formatAmount, numberWithSpaces } from '../utils/formatters'

import useBalances, { BalancesType } from '../hooks/use-balances'
// import { tokenToTx } from '../utils/sdk-helpers'
import FormContainer from './FormContainer'
import Flex from './Flex'
import Logo from './Logo'
import Balances from './Balances'
import AppLoader from './AppLoader'
import useWeb3 from '../hooks/useWeb3'
import { resolve } from 'path'
import Deposit from './Deposit'
import Address from './Address'
import VestingAbi from '../abis/vestingAbi'
import DepositRow from './DepositRow'
import AddressInput from './AddressInput'
import SubmitButton from './SubmitButton'
import CancelButton from './CancelButton'
import AmountInput from './AmountInput'

// styles

const Error = styled.div`
  margin-bottom: 20px;
`

const InfoContainer = styled.div`
  font-family: 'Fira Mono', monospace;
  font-size: 13px;
`

const ModalTitle = styled.div`
  color: black;
  font-family: 'Fira Mono', monospace;
  font-size: 20px;
  padding-bottom: 20px;
`

const FormattedAmount = styled.div`
  color: black;
  font-family: 'Fira Mono', monospace;
  font-size: 12px;
  padding-bottom: 10px;
`

const DepositButton = styled.div`
  background-color: #008844;
  color: white;
  display: flex;
  border-radius: 5px;
  font-family: 'Fira Mono', monospace;
  width: 120px;
  height: 45px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 15px;
  user-select: none;

  box-shadow: 0 2px 3px rgba(0,255,0,0.30);
  transition: all 0.3s ease-in-out;

  :hover {
    background-color: #009944;
    box-shadow: 0 3px 5px rgba(0,255,0,0.5);
    transition: opacity 0.3s ease-in-out;
  }
`
// fetch funcs

const contractAddress = '0x1ba8624B418BFfaA93c369B0841204a9d50fA4D5'; // mainnet
// const contractAddress = '0xbB6952cE0802E89A86f53443768fad7eA996285d'; // stagenet

const explorerBaseUrl = `https://explorer.haqq.network`; // mainnet
// const explorerBaseUrl = `https://explorer.stagenet.haqq.network`; // stagenet


const fetchAddressesInt = () => new Promise((resolve, reject) => {
  fetch(
    `${explorerBaseUrl}/api?module=account&action=txlistinternal&address=${contractAddress}`,
  )
    .then(res => res.json())
    .then(json => {
      const addresses = json.result
        .filter((v: any) => v.value !== '0')
        .filter((v: any) => v.to === contractAddress.toLocaleLowerCase())
        .map((v: any) => {
          const addr = {} as Address;

          addr.fromAddress = v.from;
          addr.ownerAddress = `0x${v.input.split('0xf340fa01000000000000000000000000')[1]}`;

          return addr
        })

      resolve(addresses)
    })
})


const fetchAddresses = () => new Promise((resolve, reject) => {
  fetch(
    `${explorerBaseUrl}/api?module=account&action=txlist&address=${contractAddress}`,
  )
    .then(res => res.json())
    .then(json => {
      const addresses = json.result
        .filter((v: any) => v.value !== '0')
        .map((v: any) => {
          const addr = {} as Address;

          addr.fromAddress = v.from;
          addr.ownerAddress = `0x${v.input.split('0xf340fa01000000000000000000000000')[1]}`;

          return addr
        })

      resolve(addresses)
    })
})

const fetchAllDeposits = (list_addrs: Address[], contract: any): Promise<Deposit[]> => new Promise((resolve, reject) => {
  const filtered_list_addrs: Address[] = [];

  list_addrs.forEach((curr_addr: Address) => {
    let already_has = false;

    for (let addr_index_2 = 0; addr_index_2 < filtered_list_addrs.length; addr_index_2++) {
      const old_addr: Address = filtered_list_addrs[addr_index_2];

      if (curr_addr.ownerAddress === old_addr.ownerAddress) {
        already_has = true;
      }
    }

    if (already_has === false) {
      filtered_list_addrs.push(curr_addr);
    }  
  });

  ///////////////////////

  resolve(getDeposits(filtered_list_addrs, contract));
})

async function getDeposits(list_adrrs: Address[], contract: any): Promise<Deposit[]> {
  const deposits: Deposit[] = [];

  await Promise.all(list_adrrs.map(async (addr: Address, addrIndex) => {
    const depositsCount = await contract.methods.depositsCounter(addr.ownerAddress).call();

    console.log(`deposit counter addrfrom: ${addr.fromAddress}, addrowner: ${addr.ownerAddress}, count: ${depositsCount}`);


    for (let dep_index = 1; dep_index <= depositsCount; dep_index++) {
      const deposit: Deposit = await contract.methods.deposits(addr.ownerAddress, dep_index).call();
      const amountToWithdraw = await contract.methods.amountToWithdrawNow(addr.ownerAddress, dep_index).call();

      deposit.fromAddress = addr.fromAddress;
      deposit.ownerAddress = addr.ownerAddress;
      deposit.timestamp = Number(deposit.timestamp);
      deposit.amountForWithdrawl = amountToWithdraw;
      
      deposits.push(deposit);
    }
  }));

  console.log(`deposits: `, deposits);

  return deposits;
};

const App = (): React.ReactElement => {
  const { sdk, safe } = useSafeAppsSDK()
  const { web3Proxy } = useWeb3()
  const {
    assets,
    loaded,
    error: balancesError,
    // selectedTokens,
    // setSelectedTokens,
  }: BalancesType = useBalances(safe.safeAddress, safe.chainId)

  const [vestingContract, setVestingContract] = useState<EthContract>()
  //const [submitting, setSubmitting] = useState(false)
  const [deposits, setDeposits] = useState<Deposit[]>([])
  //const [isFinished, setFinished] = useState<boolean>(false)
  const [toAddress, setToAddress] = useState<string>('')
  const [depositAmount, setDepositAmount] = useState<string>('1')
  const [error, setError] = useState<string>('')
  const [amountError, setAmountError] = useState<string>('')
  const [gasPrice, setGasPrice] = useState<BigNumber>(new BigNumber(0))
  const [networkPrefix, setNetworkPrefix] = useState<string>('')
  const [isFetched, setIsFetched] = useState<boolean>(false)
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false)
  const [islmBalance, setISLMBalance] = useState<string>('')

  const onError = (userMsg: string, err: Error) => {
    setError(`${userMsg}: ${err.message}`)
    console.error(userMsg, err)
  }

  const submitTx = async (): Promise<void> => {
    //console.log(`submit!`);

    if (!web3Utils.isAddress(toAddress)) {
      setError('Please enter a valid beneficiary address')
      return
    }

    if (amountError !== '') {
      return
    }

    const depAmount = Number(depositAmount) * Number(1000000000000000000);
    const value = depAmount.toLocaleString('fullwide', { useGrouping: false });

    // console.log(`value: ${value}`);

    const txs = [
      {
        to: contractAddress,
        value: value,
        data: vestingContract?.methods.deposit(toAddress).encodeABI(),
      },
    ]

    try {
      const response = await sdk.txs.send({ txs })
      console.log(`response: ${JSON.stringify(response)}`)

      setModalIsOpen(false)
      setDepositAmount('1000000000000000000')
    } catch (error) {
      console.error('Lock: Transaction rejected or failed: ', error)
    }

  }

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault()

    submitTx()
  }

  const callWithdrawl = (deposit: Deposit) => {
    // console.log(deposit)
  }

  const onCancel = () => {
    //console.log(`onCancel`);
    setError('');
    closeDepositModal();
    // setSubmitting(false)
  }

  const openDepositModal = () => {
    //console.log('depositOnClick');
    setModalIsOpen(true);
  }

  const closeDepositModal = () => {
    setModalIsOpen(false);
  }

  const onToAddressChange = useCallback((address: string): void => {
    console.log(`onToAddressChange addr: ${address}`);

    if (address.includes(":")) {
      address = address.split(":")[1];
    }

    console.log(`after addr: ${address}`);

    setToAddress(address)
    setError('')
  }, [])

  const onAmountValueChange = useCallback((value: string): void => {
    const num = Number(value);

    if (num < 1) {
      setAmountError('Minimum amount is 1 ISLM');
    } else {
      setAmountError('');
    }

    const clear = value.trim();
    setDepositAmount(clear);
  }, [])

  const getAddressFromDomain = useCallback(
    (address: string) => web3Proxy?.eth.ens.getAddress(address) || Promise.resolve(address),
    [web3Proxy],
  )

  // https://explorer.haqq.network/api?module=account&action=txlist&address=0x1ba8624B418BFfaA93c369B0841204a9d50fA4D5

  useEffect(() => {
    if (!web3Proxy || isFetched) return;

    const fetchData = async () => {
      let list_addrs: Address[] = await fetchAddresses() as Address[];
      const list_addrs_int: Address[] = await fetchAddressesInt() as Address[];
      list_addrs = list_addrs.concat(list_addrs_int)

      setIsFetched(true)

      if (web3Proxy) {
        const contract: EthContract = new web3Proxy.eth.Contract(VestingAbi, contractAddress);
        setVestingContract(contract);

        const deposits: Deposit[] = await fetchAllDeposits(list_addrs, contract);

        deposits.sort((d1: Deposit, d2: Deposit) => 
          d2.timestamp - d1.timestamp
        );
        
        setDeposits(deposits);

        const blockNumber = await web3Proxy.eth.getBlockNumber();
        console.log(`block_number: ${blockNumber}`);
      }
    };

    fetchData();
  }, [web3Proxy, isFetched])

  useEffect(() => {
    if (balancesError) {
      onError('Failed fetching balances', balancesError)
    }
  }, [balancesError])

  useEffect(() => {
    sdk.eth.getGasPrice().then((gasPrice: string) => {
      setGasPrice(new BigNumber(gasPrice))
    })
  }, [sdk.eth])

  useEffect(() => {
    const islm_asset = assets.filter((asset) => asset.tokenInfo.symbol === "ISLM")[0]

    if (islm_asset?.balance) {
      const balance = formatAmount(islm_asset.balance)
      setISLMBalance(balance)
    }
  }, [assets])

  const ethFiatPrice = Number(assets[0]?.fiatConversion || 0)

  useEffect(() => {
    const getChainInfo = async () => {
      try {
        const { shortName } = await sdk.safe.getChainInfo()
        setNetworkPrefix(shortName)
      } catch (e) {
        console.error('Unable to get chain info:', e)
      }
    }

    getChainInfo()
  }, [sdk])

  if (!loaded) {
    return <AppLoader />
  }

  return (
    <FormContainer>
      <Flex>
        <Logo />
        <Title size="md">Admin panel</Title>
      </Flex>

      <InfoContainer>
        {`Balance: ${islmBalance} ISLM`}<br/><br/>
        Contract address: <a href={`https://explorer.haqq.network/address/${contractAddress}`} target="_blank" rel="noreferrer">{contractAddress}</a>
      </InfoContainer>

      <DepositButton onClick={openDepositModal}>
        Deposit
      </DepositButton>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeDepositModal}
        contentLabel="Deposit Modal"
        className="deposit-modal"
        overlayClassName="modal-overlay"
        closeTimeoutMS={100}
        ariaHideApp={false}
      >
        <ModalTitle>
          New Deposit
        </ModalTitle>
        <AddressInput
          id="recipient"
          name="toAddress"
          label="Beneficiary Address"
          address={toAddress}
          hiddenLabel={false}
          onChangeAddress={onToAddressChange}
          showNetworkPrefix={false}
          getAddressFromDomain={getAddressFromDomain}
          error={error}
        />
        <AmountInput
          id="recipient"
          name="toAddress"
          label="Amount"
          value={depositAmount}
          hiddenLabel={false}
          onChange={e => onAmountValueChange(e.target.value)}
          error={amountError}
        />
        {/* <FormattedAmount>Decimals: 18</FormattedAmount> */}
        <FormattedAmount>ISLM amount: {numberWithSpaces(depositAmount)} ISLM</FormattedAmount>
        <form onSubmit={onSubmit} onReset={onCancel}>
          <Flex>
            <SubmitButton>
              Submit
            </SubmitButton>
            <CancelButton>
              Cancel
            </CancelButton>
          </Flex>
        </form>
      </Modal>

      {deposits.length > 0 ? (
        <>
          {/* <Balances
            ethFiatPrice={ethFiatPrice}
            gasPrice={gasPrice}
            assets={assets}
            onSelectionChange={setSelectedTokens}
          /> */}
          {<DepositRow key={0}/>}
          {
            deposits.map((object, i) => <DepositRow withdrwalAction={callWithdrawl} deposit={object} key={i+1} />)
          }
        </>
      ) : (
        <div/>
      )}
    </FormContainer>
  )
}

export default App
