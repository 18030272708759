import Deposit from './Deposit'
import Flex from './Flex'
import styled, { css } from 'styled-components'
import moment from 'moment'
import { formatAmount } from '../utils/formatters'

const CellContainer = styled.div`
  background-color: #00000005;
  padding: 10px 10px;
  display: flex;
  border-radius: 5px;
  font-family: 'Fira Mono', monospace;

  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
  transition: all 0.3s ease-in-out;

  :hover {
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
    transition: opacity 0.3s ease-in-out;
  }
`

const AddressRow = styled.div`
  display: flex;
  // background-color: #00990010;
  align-items: center;
  justify-content: center;

  font-size: 11px;
  font-weight: 500;
  width: 310px;
`

const TimeRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  // background-color: #99990090;
  font-size: 10px;
  font-weight: 500;
  width: 100px;
  border-left: 1px solid black;
`

const SumDepositedRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  // background-color: #00999990;
  font-size: 11px;
  font-weight: 500;
  width: 270px;
  border-left: 1px solid black;
`

const PaidRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  // background-color: #99999990;
  font-size: 11px;
  font-weight: 500;
  width: 270px;
  border-left: 1px solid black;
`

const WithdrawlRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  // background-color: #99779990;
  font-size: 11px;
  font-weight: 500;
  width: 270px;
  border-left: 1px solid black;
`

function DepositRow({ withdrwalAction, deposit }: { withdrwalAction?: any; deposit?: Deposit }) {
  if (deposit) {
    let cellStyle = {}

    if (deposit.amountForWithdrawl > 0) {
      cellStyle = { background: '#50900020', cursor: 'pointer' }
    }

    const onClick = () => {
      if (deposit.amountForWithdrawl > 0) {
        // console.log(`test`)

        withdrwalAction(deposit)
      }
    }

    return (
      <Flex>
        <CellContainer style={cellStyle} onClick={onClick}>
          <AddressRow>{deposit.fromAddress}</AddressRow>
          <AddressRow>{deposit.ownerAddress}</AddressRow>
          <TimeRow>{moment.unix(Number(deposit.timestamp)).format('DD MMM YYYY')}</TimeRow>
          <SumDepositedRow>
            {formatAmount(deposit.sumInWeiDeposited)}
          </SumDepositedRow>
          <PaidRow>
            {formatAmount(deposit.sumPaidAlready)}
          </PaidRow>
          <WithdrawlRow>{formatAmount(deposit.amountForWithdrawl.toString())}</WithdrawlRow>
        </CellContainer>
      </Flex>
    )
  }

  return (
    <Flex>
      <CellContainer>
        <AddressRow>{`From`}</AddressRow>
        <AddressRow>{`Address`}</AddressRow>
        <TimeRow>{`Time`}</TimeRow>
        <SumDepositedRow>{`Deposited`}</SumDepositedRow>
        <PaidRow>{`Paided`}</PaidRow>
        <WithdrawlRow>{`Amount for Withdrawl`}</WithdrawlRow>
      </CellContainer>
    </Flex>
  )
}

export default DepositRow
